import "vite/modulepreload-polyfill";
import "./main.css";

import * as Sentry from "@sentry/vue";

import { createApp, DefineComponent, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import axios from "axios";

import routeReverse from "@/plugins/route-reverse";
import translations from "@/plugins/translations";

const pages = import.meta.glob("./pages/**/*.vue");

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

createInertiaApp({
    resolve: async (name) => {
        return (await pages[`./pages/${name}.vue`]()) as DefineComponent;
    },
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(routeReverse)
            .use(translations);

        if (props.initialPage.props.envSentryDsn) {
            Sentry.init({
                app: vueApp,
                dsn: props.initialPage.props.envSentryDsn as string,
                integrations: [Sentry.browserTracingIntegration()],
                environment: props.initialPage.props.envEnvironment as string,
                release: props.initialPage.props.envReleaseReference as string,
            });
        }
        vueApp.mount(el);
    },
    progress: {
        color: "#d6012e",
    },
});
