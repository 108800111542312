/* eslint-disable */

const routes = {"customer_portal:home": "/klanten/", "customer_portal:plans:list": "/klanten/sleutels/", "customer_portal:users:login": "/klanten/inloggen/", "customer_portal:users:logout": "/klanten/uitloggen/", "customer_portal:users:profile": "/klanten/profiel/", "customer_portal:users:password_reset": "/klanten/wachtwoord-vergeten/", "customer_portal:users:password_reset_done": "/klanten/wachtwoord-vergeten/verstuurd/", "customer_portal:users:password_reset_confirm": "/klanten/wachtwoord-vergeten/opnieuw-instellen/<uidb64>/<token>/", "customer_portal:users:password_reset_complete": "/klanten/wachtwoord-vergeten/voltooid/", "customer_portal:orders:create": "/klanten/nabestelling/", "customer_portal:orders:complete": "/klanten/nabestelling/bedankt/", "orders:create": "/", "orders:complete": "/bedankt/"};
const reverseUrl  = function(urlName) {
  var url = routes[urlName];
  if (!url) {
    throw "URL '" + urlName + "' was not found.";
  }

  const args = arguments;
  const argTokens = url.match(/<\w*>/g);
  if (!argTokens && args[1] !== undefined) {
    throw "Invalid URL lookup: URL '" + urlName + "' does not expect any arguments.";
  }

  if (typeof (args[1]) == 'object' && !Array.isArray(args[1])) {
    argTokens.forEach(function(token) {
      const argName = token.slice(1, -1);
      const argValue = args[1][argName];
      if (argValue === undefined) {
        throw "Invalid URL lookup: Argument '" + argName + "' was not provided.";
      }

      url = url.replace(token, argValue);
    });
  } else if (args[1] !== undefined) {
    const argsArray = Array.isArray(args[1]) ? args[1] : Array.prototype.slice.apply(args, [1, args.length]);
    if (argTokens.length !== argsArray.length) {
      throw "Invalid URL lookup: Wrong number of arguments ; expected " + argTokens.length + " arguments.";
    }

    argTokens.forEach(function(token, i) {
      const argValue = argsArray[i];
      url = url.replace(token, argValue);
    });
  }

  return url;
};


export default reverseUrl;
